import React from 'react'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import { graphql } from 'gatsby'
import Navegar from '../components/navegar'

// #region GraphQL
export const query = graphql`
  {
    allTipsViajandoEnAvion1Yaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const TipsViajandoEnAvion1 = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsViajandoEnAvion1Yaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogtaviones1enezeizajpg')}
                title="Antes de la partida. Ezeiza, 28 de febrero 2012, inicio de viaje."
                leyenda="Antes de la partida. Ezeiza, 28 de febrero 2012, inicio de viaje."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>¿Qué me pongo para viajar?</h2>
                <p> ¡Socorro! Acá pleno verano allá pleno invierno. </p>
                <p>
                  Es un problema porque muchas veces pasamos de pleno invierno a pleno verano o
                  alrevés ¡Una vez cambié 40º C en unas horas! ¡En Roma 38º C y en Ezeiza 2º C bajo
                  cero!
                </p>
                <p>
                  Yo en el avión siempre tengo un poco de frío, claro que es porque viajo del lado
                  de la ventana y se nota la diferencia de temperatura, tengo frío en la parte del
                  cuerpo que está contra la ventana. Me dan una mantita pero no me resulta
                  suficiente. De manera que la campera la llevo conmigo y en parte la uso como
                  "colchoncito o almohada o lo que venga" ja, ja, además de la almohadita que me
                  dan.
                </p>
                <p>
                  Me resulta cómodo viajar con leggings de tela intermedia, que sirvan para invierno
                  y verano. Aunque si sé que voy a llegar al calor espantoso me llevo una pollerita
                  de tela, de las que no se arrugan, en la mochila y me cambio en cuanto bajo al
                  aeropuerto.
                </p>
                <p>
                  Y después llevo una remerita sin mangas, de verano, y un saquito de media
                  estación.
                </p>
                <p>
                  Aprovecho los bolsillos de la campera para llevar la chalina, la bufanda y los
                  guantes. Así no ocupan lugar en la valija!!! y…no agregan peso!
                </p>
                <p>
                  En cuanto al calzado tené en cuenta que los pies se hinchan en el avión. Tenés que
                  viajar cómoda, sobre todo porque hay que caminar mucho en los aeropuertos y hasta
                  llegar al hotel y arrastrando valijas!!! ¡Yo que uso plantillas aprovecho para
                  viajar con botitas cortas porque les saco las plantillas y queda lugar para que
                  mis pies se hinchen todo lo que quieran! Si voy a llegar al verano entonces llevo
                  a mano un par de sandalias para cambiarme en cuanto llegue al aeropuerto.
                </p>
                <p>
                  Como ves en la foto no viajé cómoda. Ay la inexperiencia... la foto corresponde al
                  inicio de un viaje, ese soñado del 2012, el primero que hice sola. En febrero,
                  pleno verano acá a ….. pleno invierno allá. La cara al llegar debe de haber sido
                  muy distinta!!! Mis piecitos!!!
                </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <p>
                  En esta foto si bien estoy dentro del aeropuerto como puedes ver ya me puse el
                  abrigo. Corresponde a la llegada y la alegría me permitió disimular el
                  sufrimiento, zapatos de taco alto me rompieron los pies. Sí ¡con los zapatos de
                  taco sufrí como loca! Con el tiempo cambié la pollera por los leggings y los tacos
                  por las botitas cortas y amplias.
                </p>
                <p>
                  Lo de más está bien, o sea, remerita de mangas cortas, saquito atado a la cintura,
                  amplia campera llena de bolsillos para meter todos los complementos de abrigo,
                  riñonera y mochilita (cambié por una más linda). Y como ves todo esto me resultó
                  útil en el aeropuerto en invierno.
                </p>
                <p> ¿Y jeans? </p>
                <p>
                  Si piensas que te vas a sentir cómoda 14 horas sentada, van bien. A mí
                  particularmente no me resultan tan cómodos. Los leggings se ajustan al cuerpo pero
                  no lo presionan tanto. Y no tienen cierres y cinturones. Son más prácticos. Los
                  baños de los aviones son súper diminutos.
                </p>
                <p>¿Y pantalones?</p>
                <p>
                  A mí me impresiona al ir al baño y que los pantalones al no estar ajustados a la
                  pierna puedan tocar el suelo o cualquier otra cosa. De todas maneras es una
                  particularidad mía.
                </p>
                <p>Imprescindibles las medias de compresión.</p>
                <p>
                  Otra recomendación es viajar con medias de compresión, de 70 a 140 den. Aquellas
                  que cubren hasta el muslo inclusive. Hasta 70 den se consiguen en las casas de
                  medias, creo que las de 140 solamente en las ortopedias. La sorpresa para mí es
                  que me imaginaba que serían horribles. Pero noooo, al menos las de 70. Las compré
                  negras y son hermosas, tan lindas que las usé para estar elegante en Roma.
                </p>
                <p> Post, abril 22, 2018. </p>
              </TextoEnMinipagina>
            </Minipagina>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogtaviones2enfiumicinojpg')}
                title="En el aeropuerto Leonardo da Vinci. Fiumicino. 29 de febrero 2012, pasajera en tránsito."
                leyenda="En el aeropuerto Leonardo da Vinci. Fiumicino. 29 de febrero 2012, invierno."
              />
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsViajandoEnAvion1
